<template>
<div>
    <!-- <v-dialog v-model="showAddNewOrder" v-if="showAddNewOrder" persistent>
      <order-purchase-new
        @onClose="
          showAddNewOrder = false;
          $refs.crud.refresh();
        "
        v-if="showAddNewOrder"
      ></order-purchase-new>
    </v-dialog> -->

    <!-- <div v-if="dialogEdit"> -->
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <order-purchase-edit @onClose="
            dialogEdit = false;
            $refs.crud.refresh();
          " :value="parentID" :order="{}" :viewLoad="true" />
        </v-card>
    </v-dialog>

    <v-dialog v-if="dialogServed" v-model="dialogServed" persistent width="100%">
        <v-card>
            <order-purcharse-served @onClose="
            dialogServed = false;
            $refs.crud.refresh();
          " :value="parentID" :order="{}" :viewLoad="true" />
        </v-card>
    </v-dialog>

    <!-- <v-dialog v-if="dialogNewOC" v-model="dialogNewOC" persistent width="100%">
      <v-card>
        <order-purcharse-new-pending
          @onClose="
            dialogNewOC = false;
            $refs.crud.refresh();
          "
          :value="parentID"
          :typeOrder="1"
          :order="{}"
          :viewLoad="true"
        />
      </v-card>
    </v-dialog> -->

    <v-dialog v-if="viewCotizationDialog" v-model="viewCotizationDialog" persistent width="100%">
        <v-card>
            <ord-cuardro-comparativo @onClose="viewCotizationDialog = false" :value="item.CttID"></ord-cuardro-comparativo>
        </v-card>
    </v-dialog>

    <div v-if="showFilesDialog">
        <v-dialog class="elevation-0" v-if="showFilesDialog" v-model="showFilesDialog" width="100%" persistent transition="dialog-bottom-transition">
            <order-show-files @close="showFilesDialog = false" :item="item" />
        </v-dialog>
    </div>

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" max-width="40%">
            <v-card>
                <s-toolbar :label="'Rechazo de Orden - ' + item.OrdDocumentNumber"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-textarea label="Motivo de Rechazo" autofocus v-model="OrdObservationDecline" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="declineOrd(item)">Rechazar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <!-- </div> -->
    <v-row>
        <v-col md="12" lg="12" sm="12" class="pb-1">
            <s-crud class="elevation-0 table table-hover table-condensed" no-full height="400" :excel="item.OrdStatus != 3" add @add="showAddNewOrder = true" sortable :view="parentID > 0" searchInput :remove="
            item.OrdStatus != 5 &&
            item.OrdStatus != 4 &&
            item.OrdStatus != 6 &&
            item.OrdStatus != 3 &&
            item.OrdStatus != 2 &&
            item.OrdStatus != 8 &&
            parentID > 0
          " add-no-function :pdf="item.OrdStatus == 1" @pdf="pdf($event)" delete-no-function @delete="deleteEvent($event)" :config="this.config" @save="save($event)" ref="crud" :filter="filter" @edit="dialogEdit = true" title="Orden de Compra" @rowSelected="selectRows($event)">
                <template v-slot:options>
                    <div>
                        <!-- <v-btn
                elevation="0"
                v-if="
                  item.OrdStatus == 5 &&
                  item.OrdStatusReception == 7 &&
                  item.OrdID_Traza == null
                "
                x-small
                color="#5175af"
                @click="newOrder(item)"
              >
                <i class="fas fa-kiwi-bird"></i>
              </v-btn> -->

                    </div>
                    <div v-if="files==null">
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on }">

                            </template>
                            <span>Duplicar</span>
                        </v-tooltip> -->

                        <v-btn icon v-on="on" small elevation="0" @click="duplicateOrderPurcharse()">
                            <v-icon color="default" x-small>
                                fas fa-copy
                            </v-icon>
                            <!--  -->
                        </v-btn>
                    </div>
                    <div v-if="files==null">
                        <v-btn elevation="0" v-if="item.OrdStatus == 1" x-small icon @click="dialogDissaproved = true">
                            <i class="far fa-thumbs-down" style="color: red; font-size: 16px"></i>
                        </v-btn>
                    </div>

                    <div v-if="files==null">
                        <v-btn elevation="0" v-if="item.OrdStatus == 1 || item.OrdStatus == 2" x-small color="#5175af" @click="orderServed(item)">
                            <i class="fas fa-truck"></i>
                        </v-btn>
                    </div>
                </template>

                <template v-slot:OrdTotal="{ row }">
                    <v-container>
                        <v-row justify="center">
                            <v-col>
                                {{
                    row.OrdTotal >= 1000
                      ? row.OrdTotal.toLocaleString("es-MX", {
                          minimumFractionDigits: 2,
                        })
                      : row.OrdTotal.toFixed(2)
                  }}
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <!-- <template v-slot:OrdTotalNew="{ row }">
                    <v-container>
                        <v-row justify="center">

                            <v-col>
                                {{
                    row.OrdTotalNew >= 1000
                      ? row.OrdTotalNew.toLocaleString("es-MX", {
                          minimumFractionDigits: 2,
                        })
                      : row.OrdTotalNew.toFixed(2)
                  }}
                            </v-col>
                        </v-row>
                    </v-container>
                </template> -->
                <template v-slot:filter>
                    <v-container>
                        <v-row justify="center" class="mb-1">
                            <v-col lg="3" class="pt-0 pb-0" v-if="$fun.isAdmin()">
                                <s-select label="Usuario" placeholder="Digite Usuario" autofocus autocomplete clearable item-text="NtpFullName" v-model="filter.UsrCreateID" item-value="UsrID" :items="itemsUsers"></s-select>
                            </v-col>
                            <v-col lg="3" md="3" cols="6" class="pt-0 pb-0">
                                <c-provider v-model="filter.SupCode" />
                            </v-col>
                            <v-col lg="2" md="2" cols="6" class="pt-0 pb-0">
                                <s-date label="Fecha Inicio" clearable v-model="filter.BeginDate" />
                            </v-col>
                            <v-col lg="2" md="2" cols="6" class="pt-0 pb-0">
                                <s-date label="Fecha Fin" clearable v-model="filter.EndDate" />
                            </v-col>
                            <v-col lg="2" md="2" cols="12" class="pt-0" v-if="files==null">
                                <s-select-definition clearable nullable label="Estados" :def="1154" v-model="filter.OrdStatus" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template v-slot:OrdStatusName="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="
                    row.OrdStatus == 1
                      ? 'green'
                      : row.OrdStatus == 2
                      ? 'brown'
                      : row.OrdStatus == 3
                      ? 'red'
                      : row.OrdStatus == 4
                      ? 'blue'
                      : row.OrdStatus == 5
                      ? 'brown'
                      : row.OrdStatus == 6
                      ? 'orange'
                      : row.OrdStatus == 8
                      ? 'red'
                      : ''
                  " style="font-size: 16px">
                                {{
                    row.OrdStatus == 1
                      ? "far fa-thumbs-up"
                      : row.OrdStatus == 2
                      ? "fas fa-truck"
                      : row.OrdStatus == 3
                      ? "far fa-thumbs-down"
                      : row.OrdStatus == 4
                      ? "fa-regular fa-clock"
                      : row.OrdStatus == 5
                      ? "fas fa-lock"
                      : row.OrdStatus == 6
                      ? "fas fa-ban"
                      : row.OrdStatus == 8
                      ? "fas fa-hands"
                      : ""
                  }}
                            </v-icon>
                            <a style="margin-left: 6px; text-decoration: underline" @click="dialogApproval = true">{{ row.OrdStatusName }}</a>
                        </template>
                        <span>{{ row.OrdStatusName }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:CttDocumentNumber="{ row }">
                    <v-icon v-if="row.CttDocumentNumber!==null" class="mr-1" style="font-size:12px;color:#ff0000" @click="showCotization(row)">fa-solid fa-arrow-right</v-icon><a style="text-decoration: underline" @click="showCotization(row)">{{
              row.CttDocumentNumber
            }}</a>

                    <!-- <v-btn v-if="row.CttDocumentNumber !== null" style="margin-left: 10px" icon x-small elevation="0" @click="showCotization(row)"><i style="font-size: 16px; color: green" class="fas fa-eye"></i></v-btn> -->
                    <v-btn v-if="row.Files == 1" title="Ver Files" elevation="0" style="text-transform: capitalize" small icon @click="showFiles(row)" :color="row.RegisterComp == 1 ? 'success' : 'error'">
                        <v-icon style="font-size: 12px">fa-solid fa-cloud</v-icon>
                    </v-btn>
                </template>
                <template v-slot:OrdApplyLetters="{ row }">
                    <v-chip x-small>{{ row.OrdApplyLetters ? "Si" : "No" }}</v-chip>
                </template>

                <template v-slot:TypeOrder="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="row.TypeOrder == 1 ? 'red' : 'green'" style="font-size: 14px">
                                {{ row.TypeOrder == 1 ? "fas fa-car" : "fas fa-street-view" }}
                            </v-icon>
                        </template>
                        <span>{{ row.TypeOrder == 1 ? "Compra" : "Servicio" }}</span>
                    </v-tooltip>
                </template>
            </s-crud>
        </v-col>

        <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="60%" style="display: inline-table">
            <ApprovalLevelsDocument :DocEntry="parentID" :TypeObject="2" />
        </v-dialog>

        <v-dialog v-if="dialogOrderDuplicate" v-model="dialogOrderDuplicate" persistent>
            <lgs-order-purcharse-duplicate @onClose="dialogOrderDuplicate = false;$refs.crud.refresh();" :value="parentID" :order="{}"></lgs-order-purcharse-duplicate>
        </v-dialog>

    </v-row>

    <!--    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading> -->
</div>
</template>

<script>
//Services
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import _sPermit from "@/services/Logistics/PermitService";
import _sTransaction from '@/services/Security/ApprovalTransactionService'

//Components
import cProvider from "@/components/Logistics/cProvider.vue";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
/* import OrderPurchaseNew from "@/views/Logistics/OrderPurchase/OrderPurchaseNew"; */
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import OrderPurcharseServed from "@/views/Logistics/OrderPurchase/OrderPurcharseServed.vue";
/* import OrderPurcharseNewPending from "@/views/Logistics/OrderPurchase/OrderPurcharseNewPending"; */
import OrdCuardroComparativo from "@/views/Logistics/OrderCotization/OrdCuardroComparativo";
import OrderShowFiles from "@/views/Logistics/OrderPurchase/OrderShowFiles.vue";
/* import OrderPurcharseSend from '../OrderStore/OrderPurcharseSend.vue'; */
import LgsOrderPurcharseDuplicate from "@/views/Logistics/OrderPurchase/LgsOrderPurcharseDuplicate.vue";

export default {
    components: {
        /* OrderPurchaseNew, */
        OrderPurchaseEdit,
        cProvider,
        ApprovalLevelsDocument,
        OrderPurcharseServed,
        /* OrderPurcharseNewPending, */
        OrdCuardroComparativo,
        OrderShowFiles,
        LgsOrderPurcharseDuplicate
        /* OrderPurchaseEdit,*/
    },
    props:{
        files: null
    },

    data() {

        return {
            processing: false,
            messageProcessing: "",
            //NEW ORDER
            itemsUsers: [],
            showAddNewOrder: false,
            viewCotizationDialog: false,
            //
            item: {},
            /* itemMail: null, */
            dialogEdit: false,
            showFilesDialog: false,
            dialogServed: false,
            dialogNewOC: false,
            dialogApproval: false,
            /* dialogSendMail: false, */
            disabledRate: false,
            filter: {
                TypeOrder: 0,
                BeginDate: null,
                EndDate: null,
                OrdStatus: null,
                SupCode: null,
                UsrCreateID: null,
                Files: this.files
            },
            config: {
                model: {
                    OrdID: "ID",
                    OrdDate: "date",
                    OrdStatusName: "",
                    OrdStatus: "",
                    SecUpdate: "date",
                    OrdTotal: "",
                    OrdStatusMail: "",
                    CttDocumentNumber: "string",
                    TypeOrder: "",
                    OrdApplyLetters: "",
                },
                service: _sOrderPurchase,
                headers: [{
                        text: "ID",
                        value: "OrdID",
                        sortable: false,
                    },
                    {
                        text: "Tipo",
                        value: "TypeOrder",
                        sortable: false,
                    },
                    {
                        text: "Empresa",
                        value: "typeBusinessName",
                        width: 100,
                        sortable: false,
                    },
                    {
                        text: "Apl. Letra",
                        value: "OrdApplyLetters",
                        sortable: false,
                    },

                    {
                        text: "Nro. Documento",
                        value: "OrdDocumentNumber",
                        width: 120,
                        sortable: false,
                    },
                    {
                        text: "Nro. Doc. Cotización",
                        value: "CttDocumentNumber",
                        width: 110,
                        sortable: false,
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate",
                        sortable: false,
                    },

                    {
                        text: "Total (Inc. IGV)",
                        value: "OrdTotal",
                        sortable: false,
                    },
                    {
                        text: "Proveedor",
                        value: "SupName",
                        sortable: false,
                    },
                    {
                        text: "Usuario",
                        value: "NtpFullName",
                        sortable: false,
                    },
                    {
                        text: "Fecha Act.",
                        value: "SecUpdate",
                        sortable: false,
                    },
                    {
                        text: "Estado",
                        value: "OrdStatusName",
                        sortable: false,
                        align: "left",
                        width: 120,
                    },
                    {
                        text: "Estado Recep.",
                        value: "OrdStatusReceptionName",
                        sortable: false,
                        align: "center",
                    },
                ],
            },
            parentID: null,
            dialogDissaproved: false,
            OrdObservationDecline: null,
            dialogOrderDuplicate: false,
        }

    },
    created() {
        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });
        this.filter.UsrID = this.$fun.isAdmin() ? null : this.$fun.getUserID();
    },

    watch: {
        /* items(){
                    this.item = null
                } */
    },

    methods: {
        selectRows(items) {
            if (items.length > 0) {
                this.selected = items;
                this.parentID = items[0].OrdID;
                this.item = items[0];
            } else {
                this.parentID = null;
                this.item = {};
            }
            console.log(this.item);
        },

        pdf(item) {

            /* this.processing = true;
            item.TypePrint = 1;
            _sOrderPurchase.PDF(item, this.$fun.getUserID()).then(
                (r) => {
                    if (r.status == 200) {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, item.TypeOrder == 3 ? "Orden_Servicio" : "Orden_compra");
                        this.processing = false;
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                }
            ); */
        },

        //Anular Orden
        deleteEvent(item) {
            this.$fun.alert("Seguro de Anular Orden de Compra?", "question").then((r) => {
                if (r.value) {
                    item.OrdStatus = 6;
                    _sOrderPurchase.setStatus(item, this.$fun.getUserID()).then((r) => {
                        this.$refs.crud.refresh();
                        this.$fun.alert("Anulado Correctamente", "success");
                    });
                }
            });
        },

        orderServed(item) {
            this.dialogServed = true;
        },

        /*  newOrder(item) {
           this.dialogNewOC = true;
         }, */

        showCotization(item) {
            this.item = item;
            this.viewCotizationDialog = true;
        },

        showFiles(item) {
            this.item = item;
            console.log("showFiles", item);
            this.showFilesDialog = true;
        },

        /* dialogMail(item) {
                    if (item !== null) {
                        this.itemMail = item
                        this.dialogSendMail = true
                    }

                }, */

        //Rechazar Orden
        declineOrd(item) {
            if (this.OrdObservationDecline == null) {
                this.$fun.alert("Ingrese motivo de rechazo", "warning");
                return;
            }

            this.$fun.alert("Seguro de rechazar Orden ?", "question", false).then((val) => {
                if (val.value) {
                    item.OrdStatus = 8;
                    item.OrdObservationDecline = this.OrdObservationDecline;
                    item.UsrCreateID = this.$fun.getUserID();

                    _sOrderPurchase.declineOrd(item, this.$fun.getUserID()).then((resp) => {
                        if (resp.status == 200) {
                            this.dialogDissaproved = false;
                            this.$refs.crud.refresh();
                            this.$fun.alert("Rechazado Correctamente", "success");
                        }
                    });
                }
            });
        },

        duplicateOrderPurcharse() {
            this.dialogOrderDuplicate = true
        },

    },
};
</script>
