<template>
<div>
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" @close="processing=false;messageProcessing=''" />
    <v-card class="mt-2">
        <s-toolbar :label="'Files - Nro. Orden: ' + item.OrdDocumentNumber" close @close="$emit('close')"></s-toolbar>
        <v-card outlined class="mt-3">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">
                <v-col lg="12" cols="12" class="s-col-text">
                    <h3>Estatus</h3>

                </v-col>
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3">
                        <v-row style="margin:auto">
                            <v-col cols="12">
                                <v-switch v-model="register" label="Registrado"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

        </v-card>
        <v-card outlined class="mt-3">
            <v-row style="margin:auto; margin-top:10px; margin-bottom: 10px;">
                <v-col cols="12" class="s-col-text">
                    <v-card elevation="3">
                        <v-row style="margin:auto">
                            <v-col cols="12">
                                <v-data-table group-by="NroComp" dense :items-per-page="-1" disable-pagination hide-default-footer :headers="headers" :items="itemAttachReceived">

                                    <template v-slot:item.RqdAcceptFile="{ item }">
                                        <v-switch v-model="item.RqdAcceptFile" color="error" hide-details dense class="mb-0 mt-0" :label="item.RqdAcceptFile ? 'Por Corregir' : ''" @click="acceptFile(item)"></v-switch>
                                    </template>
                                    <template v-slot:item.RqdAcceptFileOld="{ item }">
                                        <v-switch v-model="item.RqdAcceptFileOld" disabled color="error" hide-details dense class="mb-0 mt-0" :label="item.RqdAcceptFileOld ? 'Por Corregir' : ''"></v-switch>
                                    </template>

                                    <template v-slot:item.RqdAttach="{ item }">
                                        <label>
                                            <v-icon color="green" @click="openFile({name:item.RqdAttach})">mdi-download</v-icon>{{item.RqdAttach}}
                                        </label>
                                    </template>
                                    <template v-slot:item.RqdObservations="{ item }">
                                        <s-text v-model="item.RqdObservations"></s-text>
                                    </template>

                                </v-data-table>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-card>
</div>
</template>

<script>
//Services
import _sGeneral from "@/services/HelperService.js";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";

export default {
    components: {},

    props: {
        item: null,
    },

    data() {
        return {
            itemAttach: [],
            itemAttachReceived: [],
            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],
            register: false,
            headers: [{
                    text: "Nro. Comp.",
                    value: "NroComp"

                },
                {
                    text: "Grupo",
                    value: "RqdGroup"

                },
                {
                    text: "Descripcion",
                    value: "RqdDescription"

                },
                {
                    text: "Archivo",
                    value: "RqdAttach"

                },
                {
                    text: "Estado Anterior",
                    value: "RqdAcceptFileOld"

                },
                {
                    text: "Estado",
                    value: "RqdAcceptFile"

                },
                {
                    text: "Observacion",
                    value: "RqdObservations"

                }
            ],
        };
    },
    created() {
        this.register = this.item.RegisterComp

        console.log("this.itemthis.itemthis.item", this.item)

        if (this.item.itemsAttachSupport_.length > 0) {
            this.item.itemsAttachSupport_.forEach(element => {
                element.Attach = {
                    name: element.RqdAttach
                };
            });
        }

        this.itemAttachReceived = this.item.itemsAttachSupport_

        /* if (this.item.itemsFiles.length > 0) {
            this.item.itemsFiles.forEach(element => {
                console.log(element)
                element.Attach = {
                    name: element.RqdAttach
                };
            });

            this.itemAttach = this.item.itemsFiles
        } */
    },

    watch: {
        register() {
            this.saveRegister()
        }
    },
    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 7).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        createCarpeta() {

            console.log(this.item)

            this.$fun.alert("¿ Seguro de descargar archivos ?", "question").then(val => {

                if (val.value) {

                    this.messageProcessing = ""
                    this.processing = true

                    if (this.itemAttach.length > 0) {
                        let list = []
                        this.itemAttach.forEach(element => {

                            list.push(element.RqdAttach)

                        });

                        _sGeneral.copyFiles(this.item.SupCode, this.item.OrdDocumentNumber, list).then((r) => {
                                this.processing = false;
                                console.log("createCarpeta", r)
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.errors = this.messageProcessing
                                this.processing = false;
                            });

                    }
                }
            })

        },

        saveRegister() {

            this.item.RegisterComp = this.register

            _sOrderPurchase.saveRegister(this.item, this.$fun.getUserID()).then(resp => {

            })

            /* if (this.itemAttachReceived.length > 0) {
                this.itemAttachReceived.forEach(element => {
                    if (element.RqdAcceptFile) {
                        this.$fun.alert("Existen documentos por corregir.", "warning")
                        return
                    }
                });

            } */

        },

        acceptFile(item) {
            //this.item.RegisterComp = this.register
            console.log("item", item)

            _sOrderPurchase.saveAcceptFile(item, this.$fun.getUserID()).then(resp => {

            })
        }
    },
};
</script>
